.private-flights {
  .product-list {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;

    .text {
      color: #24303c;
    }

    .logo {
      width: 100px;
    }
    .destination {
      display: flex;
      font-size: 30px;
      align-items: center;
      font-weight: 800;
      gap: 20px;
    }

    .destination-wrapper {
      display: flex;
      gap: 50px;
    }
  }
  .benefits {
    display: flex;
    place-items: center;
    justify-content: center;
    gap: 180px;
    display: flex;
    margin-top: 200px;

    .benefits-wrapper {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    .benefit {
      display: flex;
      gap: 15px;
      align-items: center;

      img {
        width: 50px;
      }
    }
    .airplain {
      width: 450px;
    }
  }

  .for-whom {
    background-image: url('assets/backgroundForYou.png');
    height: 800px;
    object-fit: contain;
    background-position: bottom;

    .text {
      color: #fff;
      max-width: 100px;
    }
  }

  .title {
    color: #ecca69;
    font-size: 45px;
  }

  .content {
    width: 60%;
    display: flex;
    place-content: center;
  }

  .about-us {
    text-align: -webkit-center;
  }

  .contact-us-private-flights {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 70px;

    .form {
      display: flex;
      flex-direction: column;
      gap: 35px;
      max-width: fit-content;
      width: auto;
      > div {
        display: flex;
        gap: 35px;
        flex-wrap: wrap;
      }

      .ant-input {
        border: 1px solid #172539;
        box-shadow: 0px 4px 0px #ecca69;
        width: 325px;
        padding: 15px;
        color: #172539;
        font-weight: 800;
        font-size: 16px;
        &::placeholder {
          color: #172539;
          font-weight: 800;
          font-size: 16px;
          font-size: 16px;
        }
      }
    }

    .ant-btn {
      background-image: url('assets/btn.png');
      height: 250px;
      width: 335px;
      color: #fff;
      font-size: 50px;
    }

    .textarea {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-input {
        height: 250px;
        width: 600px;
        max-width: 90vw;
      }
    }

    .header-1 {
      border: 1px solid #ecca69;
      font-size: 30px;
      color: #24303c;
      padding: 10px 70px;
      border-radius: 32px;
    }

    .header-2 {
      color: #707070;
      font-size: 20px;
    }
  }

  .footer {
    background-color: #fff8ea;
    margin-top: 100px;
    .footer-logo {
      position: absolute;
      top: -50px;
      width: 450px;
      max-width: 70vw;
    }

    p {
      margin-top: 70px;
    }
  }

  @media screen and (max-width: 768px) {
    .contact-us-private-flights {
      padding: 0 20px;
    }
    .benefits {
      margin-top: 40px;
      gap: 10px;
      flex-wrap: wrap-reverse;

      .airplain {
        max-width: 80vw;
      }
    }
    .product-list {
      gap: 0;
      .destination-wrapper {
        flex-wrap: wrap;
        place-content: center;
        scale: 0.8;
      }

      .title {
        font-size: 30px;
        text-align: center;
        margin: 0;
      }
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;