#order-completion {
  .title {
    color: var(--primary-color);
    font-size: 35px;
    font-weight: bold;

    @media screen and (max-width:750px) {
      text-align: center;
    }
  }

  .image {
    text-align: center;
    img {
      height: 300px;
      max-width: 100%;
    }
  }

  .name {
    color: var(--primary-color);
    font-weight: bold;
  }

  .order {
    margin-top: 15px;
    margin-bottom: 0;
    color: #000;
  }

  .order-id {
    color: #000;
    font-size: 52px;
    line-height: 45px;
    margin-bottom: 25px;
  }

  .info {
    font-size: 18px;
    text-align: center;
  }

  .more-info {
    color: #bababa;
    width: 65%;
    margin: auto;
  }

  .info-red {
    font-weight: bold;
    color: #ff2929;
    margin-bottom: 20px;
  }

  .info-fail {
    color: #000;
  }

  @media screen and (max-width:750px) {
    .title {
      text-align: center;
    }
    .more-info{
      width: 100%;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;