#course-header-2 {
  background-image: url('assets/background.png');
  height: 800px;
  background-size: cover;
  position: relative;

  .links-wrapper {
    padding-top: 50px;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;

    .link {
      &:last-child {
        border-left: none;
      }
      color: #246070;
      padding-left: 20px;
      margin-left: 20px;
      font-size: 16px;
      font-weight: bold;
      border-left: 3px solid #246070;
    }
  }

  .title {
    color: #fff;
    bottom: 265px;
    font-size: 90px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .stiker {
    position: absolute;
    width: 250px;
    left: 238px;
    top: 160px;
  }

  @media screen and (max-width: 750px) {
    height: 370px;
    .stiker {
      position: absolute;
      width: 128px;
      left: 18px;
      top: 81px;
    }
    .links {
      display: none;
      flex-direction: column;
    }

    .title {
      font-size: 47px;
      bottom: 30px;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;