.course-landing-page, .course-landing-page [class*=" ant-col"] {
  font-family: 'Heebo', sans-serif !important;
}
.course-landing-page {
  max-width: 1600px;
  margin: 0 auto;

  .content-layout {
    max-width: 1024px;
    margin: 0 auto;
  }

  .footer {
    background-color: #ffffff;
    text-align: center;
    margin: 120px auto 80px auto;
    .logo {
      width: 270px;
      opacity: 0.6;
      left: auto;
      margin-top: 40px;
    }
  }
}

.ant-layout {
  background-color: #fff;
}
@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;