#finish-successfully {

  .certificate-icon {
    background-image: url('../certificate/assets/certificate.png');
    background-size: contain;
    width: 400px;
    height: 460px;
    background-repeat: no-repeat;
    margin: auto;
  }

  .title {
    font-size: 42px;
    width: 500px;
    text-align: center;
    font-weight: lighter;
    margin: auto;
    margin-top: -80px;
  }

  .benefits-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .benefit {
    width: 27%;
    background-color: #0e1c39;
    border-radius: 32px;
    color: #fff;
    font-size: 23px;
    font-weight: bold;
    padding: 0 30px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;

    img {
      width: 100%;
    }

    .benefit-title {
      margin-top: -60px;
    }
  }

  @media screen and (max-width: 750px) {
    .title {
      width: 100%;
      font-size: 32px;
    }
    .certificate-icon {
      width: 400px;
      height: 500px;
    }
    .benefit {
      width: 80%;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;