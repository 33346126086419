#experts-slider {
  margin: 40px auto 60px auto;

  .title {

    text-align: center;
    font-size: 50px;
    margin-bottom: 24px;
    font-weight: 500;
  }
  .img {
    max-width: 100px;
    max-height: 70px;
  }

  .splide__list {
    align-items: center;
  }

  @media screen and (max-width: 750px) {
    .title {
      font-size: 40px;
    }
    .img {
      max-width: 130px;
      max-height: 130px;
    }

  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;