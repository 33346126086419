@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Heebo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  font-family: 'Heebo', sans-serif;
  font-size: 18px;
}

.content-wrapper {
  max-width: 1450px;
  width: 90vw;
  margin: 0 auto;
}

.ease {
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.sky360-landing-page {
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100vh;
}
@media screen and (max-width: 768px) {
  .sky360-landing-page {
    margin: 0px 10px;
  }
}
.sky360-landing-page section {
  max-width: 1100px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}
.sky360-landing-page a {
  color: #000;
}

footer#contact-us a:hover {
  cursor: pointer !important;
  text-decoration: underline;
}
footer#contact-us a {
  color: #fff;
}

.sky360-landing-page a:hover {
  color: rgb(65, 65, 65);
  /* color: unset; */
  /* opacity: 0.9; */
}
.sky360-landing-page input::placeholder {
  color: #000;
}
.sky360-landing-page .ant-input-number-input {
  border-radius: 5px;
  height: 60px;
}
.sky360-landing-page .ant-input-number-handler-wrap {
  display: none;
}
