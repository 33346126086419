.product-header {
  background-image: url('./assets/background.png');
  background-size: cover;
  height: 400px;
  background-position: bottom;
  position: relative;
  margin-bottom: 100px;
  .main-desc {
    display: flex;
    > div {

    }
  }
  .links-wrapper {
    height: 90px;
  }
  .links {
    font-size: 18px;
    max-width: 1450px;
    margin: auto;
  }
  .link {
    cursor: pointer;
    margin: 0 20px;
    font-size: 24px;
  }
  .link-selected {
    background-color: white;
    border-radius: 12px;
    color: #6eceec;
  }

  .header-fixed {
    background-color: #31d5ff;
    padding: 25px 0;
    font-size: 18px;
    position: fixed;
    z-index: 100;
    width: 100vw;
    top: 0;
    left: 0;
  }
  .button-text-wrapper {
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 30px;
    padding: 46px 115px 150px 198px;
    .text {
      font-size: 35px;
      max-width: 290px;
      text-align: left;
    }

    .btn {
      border-radius: 8px;
      border: none;
      font-size: 20px;
      height: 45px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 25px;

      > span {
        margin-bottom: 3px;
      }
    }
  }
  .logo {
    width: 210px;
  }

  .ipad {
    width: 568px;
    margin-right: 140px;
  }

  .header-lower {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-between;
  }
}

.product-header-private-flight {
  background-image: url('./assets/backgroundPrivateFlights.png');
  height: 824px;
  .header-fixed {
    transition: all 500ms ease-in;
    background-color: transparent;
  }
  &.product-header .button-text-wrapper .text {
    max-width: 100%;
    font-size: 96px;
    font-weight: 500;
    position: absolute;
    top: 514px;
    width: 100%;
    left: 0;
    text-align: center;
  }

  &.product-header-scrolled {
    .header-fixed {
      background-color: rgba(253, 243, 215, 0.486);
    }
  }
}

@media screen and (max-width: 768px) {
  .product-header-private-flight {
    &.product-header .button-text-wrapper .text {
      font-size: 24px;
      top: 100px;
      left: -5%;
    }
  }

  .top-header {
    display: none;
  }
  .button-text-wrapper {
    display: none;
  }
  .logo {
    margin: 0 auto;
    display: table;
    position: relative;
    top: -29px;
    height: 70px;
  }
  .product-header {
    height: 260px;
    .button-text-wrapper {
      width: 100%;
      padding: 0;
      font-size: 20px;
      margin-top: 9px;
      margin-right: 70px;
      .text {
        text-align: right;
        font-size: 28px;
      }
    }
  }
  .ipad {
    display: none;
    //max-width: 450px;
    //width: 90vw;
    //margin: 0;
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;