.single-product {
  .list-item {
    color: #fff;
    border-radius: 24px;
    background-color: blue;
    width: 370px;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px;
    font-size: 24px;
    text-align: center;
    position: relative;
  }

  .list {
    margin-top: 100px;
    display: flex;
    gap: 150px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 140px;
  }

  .image {
    max-width: 50%;
    width: 90vw;
  }

  .logo {
    max-width: 40%;
    width: 90vw;
    margin-right: 50px;
    transform: translateX(25%);
  }

  .icon {
    position: absolute;
    top: -50px;
    width: 110px;
  }

  @media screen and (max-width: 768px) {
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 25px;
      flex-direction: column;
    }

    .logo,
    .image {
      max-width: 90%;
      margin: 0;
      transform: none;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;