#course-payment-form {

  max-width: 700px;
  background-color: #dddddd63;
  border-radius: 30px;
  padding: 30px;
  box-shadow: 0px 0px 10px #e4e0e0;
  margin: 40px auto 40px auto;
  text-align: center;

  iframe {
    margin: auto;
    height: 700px;
    width: 460px;
  }
  @media screen and (max-width: 750px) {

    iframe {
      width: 100%;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;