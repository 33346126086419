.button-with-arrow {
  justify-content: center;
  align-items: center;
  display: flex;
  .btn {
    &:hover{
      cursor: pointer;
      text-decoration: underline #00ccff;
      opacity: 0.8;
    }
    color: #54ccff;
    padding: 15px 20px;
    border-radius: 15px;
    background: #fff;
    margin: 0 20px;
    font-size:30px ;
  }
}
@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;