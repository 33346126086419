#course-info {
     .content-layout {
          background-image: url("assets/sky-transperant.png");
          background-repeat: no-repeat;
          background-size: 440px;
     }
     .course-section {
          margin-bottom: 50px;
          h2 {
               font-size: 40px;
               font-weight: 100;
               margin: -17px 0 0 0;
          }
          ul {
               font-size: 18px;
               margin-right: -20px;
          }
     }
     .course-section-3 {
          ul {
               list-style: none;
          }
     }
     .course-section-1 {
        .c1,.c2 {
             width: 303px;
             font-size: 18px;

        }
     }
     .course-section-1 .box-number {
          background-color: #00264b;
     }
     .course-section-2 .box-number {
          background-color: #00ccff;
     }
     .course-section-3 .box-number {
          background-color: #246070;
     }
     .box-number {
          width: 190px;
          height: 190px;
          color: #fff;
          position: relative;
          overflow: hidden;
          i {
               font-family: Arial;
               font-size: 80px;
               font-weight: bold;
               font-style: inherit;
               position: absolute;
               left: 5px;
               top: -8px;
               opacity: 0.4;
          }
          > div {
               width: 52px;
               border-bottom: 1px solid #fff;
               position: absolute;
               bottom: 21px;
               left: 30px;
               font-size: 20px;
          }
     }
     #course-details {
          background-color: #dddddd63;
          border-radius: 30px;
          box-shadow: 0px 0px 10px #e4e0e0;
          margin-bottom: 80px;

          #course-structure .icon {
               background-image: url("assets/skyrgoup-icon.png");
          }
          #course-duty .icon {
               background-image: url("assets/question-icon.png");
          }
          #course-where {
               .content {
                    color: #54ccff;
                    font-size: 52px;
                    line-height: 60px;
               }
               .icon {
                    background-image: url("assets/location-icon.png");
               }
          }

          .detail-box {
               background-image: url('assets/detail-box.svg');
               width: 100%;
               height: 390px;
               background-size: 150% 120%;
               background-repeat: no-repeat;
               background-position: center;
               text-align: center;
               padding-top: 40px;

               .icon {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: -60px auto 25px auto;
                    width: 80px;
                    height: 80px;
               }
               .title {
                    color: #00264b;
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 20px;
               }
               .content {
                    color: #54ccff;
                    font-size: 17px;
               }
          }
          #course-duty {
               .title {
                    font-size: 17px;
                    margin: 0;
                    margin-top: -15px;
                    margin-bottom: 8px;
               }
               ul {
                    list-style: none;
                    padding: 0;
                    width: 190px;
                    margin: auto;
                    li {
                         font-size: 14px;
                         color: #00264b;
                         border-bottom: 1px solid #55ccff;
                         margin-bottom: 5px;
                         padding-bottom: 5px;
                    }
               }
               .content {
                    width: 80%;
                    margin: auto;
               }
          }
          #course-when {
               .detail-box {
                    > .title {
                         font-size: 30px !important;
                         margin-bottom: 30px !important;
                    }
               }
               ul li {
                    &:last-child {
                         border-bottom: none !important;
                    }
                    width: 80% !important;
                    padding: 7px 20px !important;
               }
          }
          #course-structure, #course-when {
               .detail-box {
                    background-size: 170% 130%;

                    > .title {
                         font-size: 40px;
                         font-weight: bold;
                         margin: 0;
                         margin-top: -29px;
                         margin-bottom: -10px;

                    }
                    .semi-title {
                         color: #54ccff;
                         font-weight: bold;
                         font-size: 17px;
                         margin: 7px 0px;
                    }
               }
               .bonus {
                    border: 4px solid #54ccff;
                    border-radius: 60px;
                    padding-bottom: 15px;
                    text-align: center;
                    margin: 10px auto;
                    background: #ffffff;
                    width: 70%;
                    .title {
                         font-size: 30px;
                         color: #00264b;
                         font-weight: bold;
                         margin: 0px;
                    }
                    .content {
                         color: #54ccff;
                         font-size: 15px;
                         line-height: 20px;
                    }
               }
               ul {
                    list-style: none;
                    font-size: 17px;
                    padding: 0;
                    li {
                         &:last-child {
                              padding: 0px 40px;
                         }
                         color: #00264b;
                         border-bottom: 1px solid #54ccff;
                         padding-bottom: 5px;
                         width: 70%;
                         margin: auto auto 5px auto;
                         font-size: 14px;
                         font-weight: bold;
                    }
               }
          }
     }
     #promote-cnt {
          color: #00264b;
          text-align: center;
          .title{
               font-size: 40px;
               font-weight: bold;
               margin-bottom: -3px;
          }
          .title2 {
               font-size: 20px;
          }
     }
     #course-who {
          .title {
               font-size: 80px;
               line-height: 100px;
               font-weight: bold;
               color: #00264b;
               max-width: 200px;
          }

          .content {
               background-image: url("assets/airplain-big.png");
               height: 100%;
               background-size: contain;
               background-repeat: no-repeat;
               background-position: center;

               ul {
                    list-style: none;
                    width: 62%;
                    font-size: 22px;
                    padding-top: 90px;
               }
          }
     }

}

@media only screen and (max-width: 600px) {
     #course-info #course-details #course-structure ul li:last-child {
          padding: 0;
     }
     #course-info #course-details #course-structure .detail-box {
          margin-bottom: 60px;
     }
     #course-info #course-details #course-structure .bonus {
          width: 80%;
     }
     #course-info .course-section-1 .c1, #course-info .course-section-1 .c2 {
          font-weight: bold;
          width: 90%;
          margin: auto;

     }
     #course-info .course-section {
          text-align: center;
          ul {
               text-align: right;
               width: 90%;
               margin-right: 0px;
          }
          .box-number {
               margin: 0 auto;
               margin-bottom: 20px;
          }
     }
     #course-info #course-who .title {
          font-size: 32px;
          max-width: 100%;
          text-align: center;
     }
}
@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;