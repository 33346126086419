.contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 70px;
  padding-bottom: 70px;

  .title {
    font-size: 35px;
    color: #18263a;
    margin: 20px 0;
  }

  .sub-title {
    font-size: 20px;
    color: #18263a;
    margin-bottom: 35px;
  }

  > img {
    max-width: 600px;
  }

  .form {
    width: 600px;
  }

  .info {
    display: flex;
    gap: 25px;
    justify-content: space-between;

    .ant-form-item {
      width: 100%;
    }
  }

  .btn {
    float: left;
  }

  .input {
    background-color: #f9f9ff;
    height: 48px;
    background-color: #f9f9ff;
    height: 48px;
    padding: 0 25px;
    box-shadow: 0px 3px 2px 0px #00000036;
    border: 1px solid #f3f3f3;
    &::placeholder {
      color: #707070;
      font-weight: 600;
    }
  }

  .ant-form {
    max-width: 600px;
  }
  .textarea {
    height: 300px;
    padding: 25px;
  }

  .btn {
    font-size: 16px;
    background-color: #6eceec;
    color: white;
    height: auto;
    border: none;
    padding: 15px 80px;
  }

  @media screen and (max-width: 768px) {
    .ant-form {
      max-width: 90vw;
    }
    > img {
      width: 90vw;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;