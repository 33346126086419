#training-register-form {
  .extra-info {
    border: 3px solid #98c7f5;
    padding: 20px;
    margin: 40px;
    border-radius: 60px;
  }
  .content-layout {
    border-radius: 50px;
    border: 1px solid #00264b;
    box-shadow: 0px 0px 90px #98c7f5;
    text-align: center;
    color: #00264b;
    max-width: 800px;
    padding: 40px 20px;
  }
  .title {
    font-weight: 100;
    font-size: 60px;
    color: #00ccff;
    &.title-med {
      font-size: 30px;
      margin: 0 auto 10px;
    }
  }
  .course-price {
    .price {
      font-size: 56px;
      font-weight: bold;
    }
    .sign {
      font-size: 26px;
      color: #00ccff;
      padding-right: 10px;
    }
    .reg-now-title{
      line-height: 30px;
    }
  }
  .more-info {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #54ccff;
  }
  .reg-now-title {
    font-size: 39px;
    font-weight: bold;
  }

  .ant-form-item {
    &.interested-in,
    &.textarea-more-info {
      width: 96%;
    }
    width: 46%;
    margin-bottom: 15px;
    .ant-picker {
      width: 100%;
      input {
        border: none;
      }
    }
    span.ant-radio + * {
      color: #54ccff !important;
      font-weight: bold;
    }
    .ant-radio {
      border-color: #54ccff !important;
      color: #54ccff !important;
    }
    input,.ant-picker,textarea,
    .ant-select-selector {
      background-color: #e8ebef;
      border: 1px solid #54ccff;
      font-size: 17px;
      border-radius: 20px;
    }
  }
  .button-with-arrow {
    position: absolute;
    bottom: -79px;
    width: 100%;
  }

  .successMessage {
    color: #71b264;
    font-size: 20px;
    text-align: center;
  }
  .course-cycles {
    width: 100%;
    .ant-form-item-control-input-content {
      display: flex;
      gap: 10px;
      align-items: center;
      .ant-select.ant-select-in-form-item {
        width: 60%;
        .ant-select-selector {
          padding: 0 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  #course-register-form .ant-form-item {
    width: 95%;
  }
}
@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;