.footer {
  background-color: #ebebf8;
  position: relative;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;

  .text {
    color: #333697;
    font-size: 20px;
    margin-top: 55px;
  }

  .logo {
    position: absolute;
    max-width: 500px;
    top: 0;
    top: -60px;
    left: calc(50% - 250px);
    width: 70vw;
  }

  @media screen and (max-width: 768px) {
    .logo {
      left: calc(50% - 35vw);
      top: -35px;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;