#promote-cnt {
  .title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .title2 {
    ul {
      list-style: none;
      max-width: 520px;
      padding: 20px;
      border: 3px solid #f2f2f2;
      border-radius: 60px;
      margin: auto;
      text-align: right;
      padding-right: 40px;
      background: #6eceec;
      box-shadow: 4px 2px 20px #8d8d8d;
      li {

      }
    }
  }
}

#course-experts {
  margin-bottom: 30px;
  .title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .experts-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 17px;
    padding: 0 99px;
  }

  .expert {
    width: 200px;
    color: #222f4a;
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  .img {
    height: 200px;
    border: 1px solid #222f4a;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 80px;
      object-fit: contain;
      max-width: 160px;
    }
  }

  @media screen and (max-width: 750px) {
    .experts-wrapper {
      padding: 0;
    }
  }
}
@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;