#webinar-header {
  position: relative;

  .aff-logo {
    max-height: 80px;
  }
  .main-title {

    text-align: center;
    font-size: 46px;
    background: #6eceec;
    display: table;
    margin: 0 auto;
    padding: 20px 50px;
    border-radius: 42px;
    .title-small {
      font-size: 25px;
    }
  }
  .back-webinar {
    background-image: url('assets/backgroun1.png');
    height: 800px;
    width: 100%;
    background-size: cover;
  }

  .links-wrapper {
    margin-bottom: 21px;
    padding-top: 50px;
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .link {
      &:last-child {
        border-left: none;
      }
      color: #246070;
      padding-left: 20px;
      margin-left: 20px;
      font-size: 16px;
      font-weight: bold;
      border-left: 3px solid #246070;
    }
  }

  .title {
    color: #fff;
    bottom: 265px;
    font-size: 90px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .stiker {
    position: absolute;
    width: 250px;
    left: 238px;
    top: 160px;
  }

  @media screen and (max-width: 750px) {
    .stiker {
      position: absolute;
      width: 128px;
      left: 18px;
      top: 81px;
    }
    .links {
      display: none;
      flex-direction: column;
    }

    .title {
      font-size: 47px;
      bottom: 30px;
    }
  }
}

@primary-color: #43DC81;@gray-secondary: #9B9B9B;@link-color: #0DD078;@success-color: #0DD078;@border-radius-base: 40px;